import { Component } from "@angular/core";
import { S25MultiselectSearchCriteriaComponent } from "../../s25-multiselect/multiselects/s25.multiselect.search.criteria.component";
import { S25ItemI } from "../../../pojo/S25ItemI";

@Component({
    selector: "s25-ng-table-multiselect-search-criteria",
    template: `
        <s25-ng-multiselect-search-criteria
            [type]="type"
            [modelBean]="modelBean"
            [honorMatching]="honorMatching"
            [customFilterValue]="customFilterValue"
            [selectedItems]="selectedItems"
            [popoverOnBody]="popoverOnBody"
            [popoverPlacement]="popoverPlacement"
            [customPopoverClass]="customPopoverClass"
            [useSecurity]="useSecurity"
            [resultsTemplate]="resultsTemplate || tableResults"
            (changed)="changed.emit($event)"
        ></s25-ng-multiselect-search-criteria>

        <ng-template #tableResults let-modelBean="modelBean">
            @if (modelBean.selectedItems.length > 0 && modelBean.showResult) {
                <div class="items">
                    @for (item of modelBean.selectedItems; track item) {
                        <div class="item">
                            <button (click)="onRemove(item)">
                                <s25-ng-icon [type]="'close'" [size]="'1.1em'"></s25-ng-icon>
                            </button>
                            {{ item.itemName }}
                        </div>
                    }
                </div>
            }
        </ng-template>
    `,
    styles: [
        `
            .items {
                padding: 0.5rem 0;
            }

            .item {
                overflow-wrap: anywhere;
                display: flex;
            }

            .item button {
                background-color: transparent;
                border: 0;
                cursor: pointer;
                padding-left: 0;
            }

            ::ng-deep .nm-party--on s25-ng-table-multiselect-search-criteria button {
                color: white;
            }
        `,
    ],
})
export class GenericTableMultiselectSearchCriteriaComponent extends S25MultiselectSearchCriteriaComponent {
    onRemove(item: S25ItemI) {
        this.modelBean.removeSelectedItem(item);
    }
}
