import { S25Util } from "../../util/s25-util";
import { ProfileUtil } from "../s25-datepattern/profile.util";
import { jSith } from "../../util/jquery-replacement";
import { BOARD_CONST } from "./s25.board.const";
import { SwarmSchedule } from "./SwarmSchedule";

export class BoardOccUtil {
    public static getOccurrences(item: Pick<SwarmSchedule.ItemI, "occs" | "dow" | "profileCode" | "start" | "end">) {
        const dt = S25Util.date.parseDropTZ(item.occs[0].reservation_start_dt);
        const desiredDow = item.dow.substring(0, 1);
        const evStartDt = S25Util.date.syncDateToTime(
            S25Util.date.getDate(S25Util.date.toDow(dt, desiredDow, "sameWeek")),
            item.start,
        );
        const evEndDt = S25Util.date.syncDateToTime(S25Util.date.getDate(evStartDt), item.end);

        //get occ based on event start/end and new profile code for repeating pattern
        return ProfileUtil.getOccurrences(
            {
                evStartDt: evStartDt,
                evEndDt: evEndDt,
                minutes: {
                    setupMinutes: 0,
                    preMinutes: 0,
                    postMinutes: 0,
                    takedownMinutes: 0,
                },
            },
            ProfileUtil.getProfileModel(
                null,
                item.profileCode,
                ProfileUtil.getProfileCodeThroughDate(item.profileCode),
                evStartDt,
            ),
        ).map(function (occ) {
            //occs need in reservation_* format
            return {
                reservation_start_dt: occ.evStartDt,
                reservation_end_dt: occ.evEndDt,
            };
        });
    }

    public static getNewProfileCode(item: SwarmSchedule.ItemI) {
        const daysOfWeek = item.daysOfWeek.map(function (dow: SwarmSchedule.Dow) {
            return BOARD_CONST.dowMapInverse[dow];
        });

        //get profile model, which includes repeatsEvery, days of week, through date, etc
        const pm = ProfileUtil.getProfileModel(
            null,
            item.profileCode,
            ProfileUtil.getProfileCodeThroughDate(item.profileCode),
            null,
        );

        //set chosen days of week based on item, which reflects any dragged changes that occurred
        jSith.forEach(pm.daysOfWeek, function (_, dowObj) {
            dowObj.chosen = daysOfWeek.indexOf(dowObj.abbr) > -1;
        });

        //return new profile code based on new chosen days of week -- note that repeats every and through date remain the same!
        const code = ProfileUtil.getProfileCodeByModel(pm);
        console.log("code", code);
        return code;
    }
}
