import { EventSummary } from "./s25.event.summary.service";

export const S25DowPatterns: EventSummary.DowPattern[] = [
    {
        dowArr: [1],
        dowString: "MO",
        dowToIndex: {
            1: 0,
        },
    },
    {
        dowArr: [2],
        dowString: "TU",
        dowToIndex: {
            2: 0,
        },
    },
    {
        dowArr: [3],
        dowString: "WE",
        dowToIndex: {
            3: 0,
        },
    },
    {
        dowArr: [4],
        dowString: "TH",
        dowToIndex: {
            4: 0,
        },
    },
    {
        dowArr: [5],
        dowString: "FR",
        dowToIndex: {
            5: 0,
        },
    },
    {
        dowArr: [6],
        dowString: "SA",
        dowToIndex: {
            6: 0,
        },
    },
    {
        dowArr: [0],
        dowString: "SU",
        dowToIndex: {
            0: 0,
        },
    },
    {
        dowArr: [1, 2],
        dowString: "MO TU",
        dowToIndex: {
            1: 0,
            2: 1,
        },
    },
    {
        dowArr: [2, 3],
        dowString: "TU WE",
        dowToIndex: {
            2: 0,
            3: 1,
        },
    },
    {
        dowArr: [3, 4],
        dowString: "WE TH",
        dowToIndex: {
            3: 0,
            4: 1,
        },
    },
    {
        dowArr: [4, 5],
        dowString: "TH FR",
        dowToIndex: {
            4: 0,
            5: 1,
        },
    },
    {
        dowArr: [5, 6],
        dowString: "FR SA",
        dowToIndex: {
            5: 0,
            6: 1,
        },
    },
    {
        dowArr: [0, 6],
        dowString: "SU SA",
        dowToIndex: {
            0: 0,
            6: 1,
        },
    },
    {
        dowArr: [0, 1],
        dowString: "SU MO",
        dowToIndex: {
            0: 0,
            1: 1,
        },
    },
    {
        dowArr: [1, 3],
        dowString: "MO WE",
        dowToIndex: {
            1: 0,
            3: 1,
        },
    },
    {
        dowArr: [2, 4],
        dowString: "TU TH",
        dowToIndex: {
            2: 0,
            4: 1,
        },
    },
    {
        dowArr: [3, 5],
        dowString: "WE FR",
        dowToIndex: {
            3: 0,
            5: 1,
        },
    },
    {
        dowArr: [1, 4],
        dowString: "MO TH",
        dowToIndex: {
            1: 0,
            4: 1,
        },
    },
    {
        dowArr: [1, 5],
        dowString: "MO FR",
        dowToIndex: {
            1: 0,
            5: 1,
        },
    },
    {
        dowArr: [2, 5],
        dowString: "TU FR",
        dowToIndex: {
            2: 0,
            5: 1,
        },
    },
    {
        dowArr: [4, 6],
        dowString: "TH SA",
        dowToIndex: {
            4: 0,
            6: 1,
        },
    },
    {
        dowArr: [3, 6],
        dowString: "WE SA",
        dowToIndex: {
            3: 0,
            6: 1,
        },
    },
    {
        dowArr: [2, 6],
        dowString: "TU SA",
        dowToIndex: {
            2: 0,
            6: 1,
        },
    },
    {
        dowArr: [1, 6],
        dowString: "MO SA",
        dowToIndex: {
            1: 0,
            6: 1,
        },
    },
    {
        dowArr: [0, 2],
        dowString: "SU TU",
        dowToIndex: {
            0: 0,
            2: 1,
        },
    },
    {
        dowArr: [0, 3],
        dowString: "SU WE",
        dowToIndex: {
            0: 0,
            3: 1,
        },
    },
    {
        dowArr: [0, 4],
        dowString: "SU TH",
        dowToIndex: {
            0: 0,
            4: 1,
        },
    },
    {
        dowArr: [0, 5],
        dowString: "SU FR",
        dowToIndex: {
            0: 0,
            5: 1,
        },
    },
    {
        dowArr: [1, 2, 3],
        dowString: "MO TU WE",
        dowToIndex: {
            1: 0,
            2: 1,
            3: 2,
        },
    },
    {
        dowArr: [2, 3, 4],
        dowString: "TU WE TH",
        dowToIndex: {
            2: 0,
            3: 1,
            4: 2,
        },
    },
    {
        dowArr: [3, 4, 5],
        dowString: "WE TH FR",
        dowToIndex: {
            3: 0,
            4: 1,
            5: 2,
        },
    },
    {
        dowArr: [3, 5, 6],
        dowString: "WE FR SA",
        dowToIndex: {
            3: 0,
            5: 1,
            6: 2,
        },
    },
    {
        dowArr: [4, 5, 6],
        dowString: "TH FR SA",
        dowToIndex: {
            4: 0,
            5: 1,
            6: 2,
        },
    },
    {
        dowArr: [0, 1, 2],
        dowString: "SU MO TU",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
        },
    },
    {
        dowArr: [1, 3, 5],
        dowString: "MO WE FR",
        dowToIndex: {
            1: 0,
            3: 1,
            5: 2,
        },
    },
    {
        dowArr: [1, 2, 4],
        dowString: "MO TU TH",
        dowToIndex: {
            1: 0,
            2: 1,
            4: 2,
        },
    },
    {
        dowArr: [1, 2, 5],
        dowString: "MO TU FR",
        dowToIndex: {
            1: 0,
            2: 1,
            5: 2,
        },
    },
    {
        dowArr: [1, 3, 4],
        dowString: "MO WE TH",
        dowToIndex: {
            1: 0,
            3: 1,
            4: 2,
        },
    },
    {
        dowArr: [1, 4, 5],
        dowString: "MO TH FR",
        dowToIndex: {
            1: 0,
            4: 1,
            5: 2,
        },
    },
    {
        dowArr: [2, 4, 5],
        dowString: "TU TH FR",
        dowToIndex: {
            2: 0,
            4: 1,
            5: 2,
        },
    },
    {
        dowArr: [2, 3, 5],
        dowString: "TU WE FR",
        dowToIndex: {
            2: 0,
            3: 1,
            5: 2,
        },
    },
    {
        dowArr: [0, 2, 3],
        dowString: "SU TU WE",
        dowToIndex: {
            0: 0,
            2: 1,
            3: 2,
        },
    },
    {
        dowArr: [0, 3, 4],
        dowString: "SU WE TH",
        dowToIndex: {
            0: 0,
            3: 1,
            4: 2,
        },
    },
    {
        dowArr: [0, 4, 5],
        dowString: "SU TH FR",
        dowToIndex: {
            0: 0,
            4: 1,
            5: 2,
        },
    },
    {
        dowArr: [0, 5, 6],
        dowString: "SU FR SA",
        dowToIndex: {
            0: 0,
            5: 1,
            6: 2,
        },
    },
    {
        dowArr: [0, 1, 3],
        dowString: "SU MO WE",
        dowToIndex: {
            0: 0,
            1: 1,
            3: 2,
        },
    },
    {
        dowArr: [0, 2, 4],
        dowString: "SU TU TH",
        dowToIndex: {
            0: 0,
            2: 1,
            4: 2,
        },
    },
    {
        dowArr: [0, 3, 5],
        dowString: "SU WE FR",
        dowToIndex: {
            0: 0,
            3: 1,
            5: 2,
        },
    },
    {
        dowArr: [0, 4, 6],
        dowString: "SU TH SA",
        dowToIndex: {
            0: 0,
            4: 1,
            6: 2,
        },
    },
    {
        dowArr: [0, 1, 4],
        dowString: "SU MO TH",
        dowToIndex: {
            0: 0,
            1: 1,
            4: 2,
        },
    },
    {
        dowArr: [0, 2, 5],
        dowString: "SU TU FR",
        dowToIndex: {
            0: 0,
            2: 1,
            5: 2,
        },
    },
    {
        dowArr: [0, 3, 6],
        dowString: "SU WE SA",
        dowToIndex: {
            0: 0,
            3: 1,
            6: 2,
        },
    },
    {
        dowArr: [0, 1, 5],
        dowString: "SU MO FR",
        dowToIndex: {
            0: 0,
            1: 1,
            5: 2,
        },
    },
    {
        dowArr: [0, 2, 6],
        dowString: "SU TU SA",
        dowToIndex: {
            0: 0,
            2: 1,
            6: 2,
        },
    },
    {
        dowArr: [0, 1, 6],
        dowString: "SU MO SA",
        dowToIndex: {
            0: 0,
            1: 1,
            6: 2,
        },
    },
    {
        dowArr: [1, 2, 6],
        dowString: "MO TU SA",
        dowToIndex: {
            1: 0,
            2: 1,
            6: 2,
        },
    },
    {
        dowArr: [1, 3, 6],
        dowString: "MO WE SA",
        dowToIndex: {
            1: 0,
            3: 1,
            6: 2,
        },
    },
    {
        dowArr: [1, 4, 6],
        dowString: "MO TH SA",
        dowToIndex: {
            1: 0,
            4: 1,
            6: 2,
        },
    },
    {
        dowArr: [1, 5, 6],
        dowString: "MO FR SA",
        dowToIndex: {
            1: 0,
            5: 1,
            6: 2,
        },
    },
    {
        dowArr: [2, 3, 6],
        dowString: "TU WE SA",
        dowToIndex: {
            2: 0,
            3: 1,
            6: 2,
        },
    },
    {
        dowArr: [2, 4, 6],
        dowString: "TU TH SA",
        dowToIndex: {
            2: 0,
            4: 1,
            6: 2,
        },
    },
    {
        dowArr: [3, 4, 6],
        dowString: "WE TH SA",
        dowToIndex: {
            3: 0,
            4: 1,
            6: 2,
        },
    },
    {
        dowArr: [2, 5, 6],
        dowString: "TU FR SA",
        dowToIndex: {
            2: 0,
            5: 1,
            6: 2,
        },
    },
    {
        dowArr: [1, 2, 3, 4],
        dowString: "MO TU WE TH",
        dowToIndex: {
            1: 0,
            2: 1,
            3: 2,
            4: 3,
        },
    },
    {
        dowArr: [2, 3, 4, 5],
        dowString: "TU WE TH FR",
        dowToIndex: {
            2: 0,
            3: 1,
            4: 2,
            5: 3,
        },
    },
    {
        dowArr: [3, 4, 5, 6],
        dowString: "WE TH FR SA",
        dowToIndex: {
            3: 0,
            4: 1,
            5: 2,
            6: 3,
        },
    },
    {
        dowArr: [0, 1, 5, 6],
        dowString: "SU MO FR SA",
        dowToIndex: {
            0: 0,
            1: 1,
            5: 2,
            6: 3,
        },
    },
    {
        dowArr: [0, 4, 5, 6],
        dowString: "SU TH FR SA",
        dowToIndex: {
            0: 0,
            4: 1,
            5: 2,
            6: 3,
        },
    },
    {
        dowArr: [1, 2, 3, 5],
        dowString: "MO TU WE FR",
        dowToIndex: {
            1: 0,
            2: 1,
            3: 2,
            5: 3,
        },
    },
    {
        dowArr: [1, 3, 4, 5],
        dowString: "MO WE TH FR",
        dowToIndex: {
            1: 0,
            3: 1,
            4: 2,
            5: 3,
        },
    },
    {
        dowArr: [1, 2, 4, 5],
        dowString: "MO TU TH FR",
        dowToIndex: {
            1: 0,
            2: 1,
            4: 2,
            5: 3,
        },
    },
    {
        dowArr: [0, 1, 2, 3],
        dowString: "SU MO TU WE",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
        },
    },
    {
        dowArr: [0, 1, 3, 4],
        dowString: "SU MO WE TH",
        dowToIndex: {
            0: 0,
            1: 1,
            3: 2,
            4: 3,
        },
    },
    {
        dowArr: [0, 1, 4, 5],
        dowString: "SU MO TH FR",
        dowToIndex: {
            0: 0,
            1: 1,
            4: 2,
            5: 3,
        },
    },
    {
        dowArr: [0, 1, 2, 4],
        dowString: "SU MO TU TH",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
            4: 3,
        },
    },
    {
        dowArr: [0, 1, 3, 5],
        dowString: "SU MO WE FR",
        dowToIndex: {
            0: 0,
            1: 1,
            3: 2,
            5: 3,
        },
    },
    {
        dowArr: [0, 1, 2, 5],
        dowString: "SU MO TU FR",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
            5: 3,
        },
    },
    {
        dowArr: [0, 2, 3, 4],
        dowString: "SU TU WE TH",
        dowToIndex: {
            0: 0,
            2: 1,
            3: 2,
            4: 3,
        },
    },
    {
        dowArr: [0, 2, 4, 5],
        dowString: "SU TU TH FR",
        dowToIndex: {
            0: 0,
            2: 1,
            4: 2,
            5: 3,
        },
    },
    {
        dowArr: [0, 2, 5, 6],
        dowString: "SU TU FR SA",
        dowToIndex: {
            0: 0,
            2: 1,
            5: 2,
            6: 3,
        },
    },
    {
        dowArr: [0, 3, 4, 5],
        dowString: "SU WE TH FR",
        dowToIndex: {
            0: 0,
            3: 1,
            4: 2,
            5: 3,
        },
    },
    {
        dowArr: [0, 3, 5, 6],
        dowString: "SU WE FR SA",
        dowToIndex: {
            0: 0,
            3: 1,
            5: 2,
            6: 3,
        },
    },
    {
        dowArr: [1, 2, 5, 6],
        dowString: "MO TU FR SA",
        dowToIndex: {
            1: 0,
            2: 1,
            5: 2,
            6: 3,
        },
    },
    {
        dowArr: [1, 3, 5, 6],
        dowString: "MO WE FR SA",
        dowToIndex: {
            1: 0,
            3: 1,
            5: 2,
            6: 3,
        },
    },
    {
        dowArr: [1, 4, 5, 6],
        dowString: "MO TH FR SA",
        dowToIndex: {
            1: 0,
            4: 1,
            5: 2,
            6: 3,
        },
    },
    {
        dowArr: [2, 3, 5, 6],
        dowString: "TU WE FR SA",
        dowToIndex: {
            2: 0,
            3: 1,
            5: 2,
            6: 3,
        },
    },
    {
        dowArr: [2, 4, 5, 6],
        dowString: "TU TH FR SA",
        dowToIndex: {
            2: 0,
            4: 1,
            5: 2,
            6: 3,
        },
    },
    {
        dowArr: [0, 1, 4, 6],
        dowString: "SU MO TH SA",
        dowToIndex: {
            0: 0,
            1: 1,
            4: 2,
            6: 3,
        },
    },
    {
        dowArr: [0, 2, 3, 5],
        dowString: "SU TU WE FR",
        dowToIndex: {
            0: 0,
            2: 1,
            3: 2,
            5: 3,
        },
    },
    {
        dowArr: [0, 2, 4, 6],
        dowString: "SU TU TH SA",
        dowToIndex: {
            0: 0,
            2: 1,
            4: 2,
            6: 3,
        },
    },
    {
        dowArr: [0, 3, 4, 6],
        dowString: "SU WE TH SA",
        dowToIndex: {
            0: 0,
            3: 1,
            4: 2,
            6: 3,
        },
    },
    {
        dowArr: [1, 2, 4, 6],
        dowString: "MO TU TH SA",
        dowToIndex: {
            1: 0,
            2: 1,
            4: 2,
            6: 3,
        },
    },
    {
        dowArr: [1, 3, 4, 6],
        dowString: "MO WE TH SA",
        dowToIndex: {
            1: 0,
            3: 1,
            4: 2,
            6: 3,
        },
    },
    {
        dowArr: [2, 3, 4, 6],
        dowString: "TU WE TH SA",
        dowToIndex: {
            2: 0,
            3: 1,
            4: 2,
            6: 3,
        },
    },
    {
        dowArr: [0, 1, 3, 6],
        dowString: "SU MO WE SA",
        dowToIndex: {
            0: 0,
            1: 1,
            3: 2,
            6: 3,
        },
    },
    {
        dowArr: [0, 1, 2, 6],
        dowString: "SU MO TU SA",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
            6: 3,
        },
    },
    {
        dowArr: [0, 2, 3, 6],
        dowString: "SU TU WE SA",
        dowToIndex: {
            0: 0,
            2: 1,
            3: 2,
            6: 3,
        },
    },
    {
        dowArr: [1, 2, 3, 6],
        dowString: "MO TU WE SA",
        dowToIndex: {
            1: 0,
            2: 1,
            3: 2,
            6: 3,
        },
    },
    {
        dowArr: [1, 2, 3, 4, 5],
        dowString: "MO TU WE TH FR",
        dowToIndex: {
            1: 0,
            2: 1,
            3: 2,
            4: 3,
            5: 4,
        },
    },
    {
        dowArr: [0, 1, 2, 3, 4],
        dowString: "SU MO TU WE TH",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
        },
    },
    {
        dowArr: [0, 1, 2, 3, 6],
        dowString: "SU MO TU WE SA",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            6: 4,
        },
    },
    {
        dowArr: [0, 1, 2, 5, 6],
        dowString: "SU MO TU FR SA",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
            5: 3,
            6: 4,
        },
    },
    {
        dowArr: [0, 1, 4, 5, 6],
        dowString: "SU MO TH FR SA",
        dowToIndex: {
            0: 0,
            1: 1,
            4: 2,
            5: 3,
            6: 4,
        },
    },
    {
        dowArr: [0, 3, 4, 5, 6],
        dowString: "SU WE TH FR SA",
        dowToIndex: {
            0: 0,
            3: 1,
            4: 2,
            5: 3,
            6: 4,
        },
    },
    {
        dowArr: [2, 3, 4, 5, 6],
        dowString: "TU WE TH FR SA",
        dowToIndex: {
            2: 0,
            3: 1,
            4: 2,
            5: 3,
            6: 4,
        },
    },
    {
        dowArr: [0, 1, 2, 4, 5],
        dowString: "SU MO TU TH FR",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
            4: 3,
            5: 4,
        },
    },
    {
        dowArr: [0, 1, 3, 4, 5],
        dowString: "SU MO WE TH FR",
        dowToIndex: {
            0: 0,
            1: 1,
            3: 2,
            4: 3,
            5: 4,
        },
    },
    {
        dowArr: [0, 1, 3, 5, 6],
        dowString: "SU MO WE FR SA",
        dowToIndex: {
            0: 0,
            1: 1,
            3: 2,
            5: 3,
            6: 4,
        },
    },
    {
        dowArr: [0, 2, 3, 4, 5],
        dowString: "SU TU WE TH FR",
        dowToIndex: {
            0: 0,
            2: 1,
            3: 2,
            4: 3,
            5: 4,
        },
    },
    {
        dowArr: [0, 2, 3, 5, 6],
        dowString: "SU TU WE FR SA",
        dowToIndex: {
            0: 0,
            2: 1,
            3: 2,
            5: 3,
            6: 4,
        },
    },
    {
        dowArr: [0, 4, 4, 5, 6],
        dowString: "SU TH TH FR SA",
        dowToIndex: {
            0: 0,
            4: 2,
            5: 3,
            6: 4,
        },
    },
    {
        dowArr: [1, 2, 3, 5, 6],
        dowString: "MO TU WE FR SA",
        dowToIndex: {
            1: 0,
            2: 1,
            3: 2,
            5: 3,
            6: 4,
        },
    },
    {
        dowArr: [1, 2, 4, 5, 6],
        dowString: "MO TU TH FR SA",
        dowToIndex: {
            1: 0,
            2: 1,
            4: 2,
            5: 3,
            6: 4,
        },
    },
    {
        dowArr: [1, 3, 4, 5, 6],
        dowString: "MO WE TH FR SA",
        dowToIndex: {
            1: 0,
            3: 1,
            4: 2,
            5: 3,
            6: 4,
        },
    },
    {
        dowArr: [0, 1, 2, 3, 5],
        dowString: "SU MO TU WE FR",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            5: 4,
        },
    },
    {
        dowArr: [0, 1, 2, 4, 6],
        dowString: "SU MO TU TH SA",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
            4: 3,
            6: 4,
        },
    },
    {
        dowArr: [0, 1, 3, 4, 6],
        dowString: "SU MO WE TH SA",
        dowToIndex: {
            0: 0,
            1: 1,
            3: 2,
            4: 3,
            6: 4,
        },
    },
    {
        dowArr: [0, 2, 3, 4, 6],
        dowString: "SU TU WE TH SA",
        dowToIndex: {
            0: 0,
            2: 1,
            3: 2,
            4: 3,
            6: 4,
        },
    },
    {
        dowArr: [1, 2, 3, 4, 6],
        dowString: "MO TU WE TH SA",
        dowToIndex: {
            1: 0,
            2: 1,
            3: 2,
            4: 3,
            6: 4,
        },
    },
    {
        dowArr: [1, 2, 3, 4, 5, 6],
        dowString: "MO TU WE TH FR SA",
        dowToIndex: {
            1: 0,
            2: 1,
            3: 2,
            4: 3,
            5: 4,
            6: 5,
        },
    },
    {
        dowArr: [0, 1, 2, 3, 4, 5],
        dowString: "SU MO TU WE TH FR",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
        },
    },
    {
        dowArr: [0, 1, 2, 3, 5, 6],
        dowString: "SU MO TU WE FR SA",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            5: 4,
            6: 5,
        },
    },
    {
        dowArr: [0, 1, 2, 4, 5, 6],
        dowString: "SU MO TU TH FR SA",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
            4: 3,
            5: 4,
            6: 5,
        },
    },
    {
        dowArr: [0, 1, 3, 4, 5, 6],
        dowString: "SU MO WE TH FR SA",
        dowToIndex: {
            0: 0,
            1: 1,
            3: 2,
            4: 3,
            5: 4,
            6: 5,
        },
    },
    {
        dowArr: [0, 1, 3, 4, 5, 6],
        dowString: "SU MO WE TH FR SA",
        dowToIndex: {
            0: 0,
            1: 1,
            3: 2,
            4: 3,
            5: 4,
            6: 5,
        },
    },
    {
        dowArr: [0, 1, 2, 3, 4, 6],
        dowString: "SU MO TU WE TH SA",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            6: 5,
        },
    },
    {
        dowArr: [0, 1, 2, 3, 4, 5, 6],
        dowString: "SU MO TU WE TH FR SA",
        dowToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
        },
    },
];
