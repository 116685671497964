//@author: devin
//@migratedBy: travis
export const BOARD_CONST = {
    animateItemEvent: "chandler-animate-item",
    destroyItemEvent: "chandler-destroy-item",
    nearestMinute: 5,
    rowHeight: 35,
    colWidth: 150,
    itemHeight: 31,
    rowNameOffset: 300,
    dowMap: {
        MO: "M",
        TU: "T",
        WE: "W",
        TH: "R",
        FR: "F",
        SA: "S",
        SU: "U",
    },
    dowMapInverse: {
        M: "MO",
        T: "TU",
        W: "WE",
        R: "TH",
        F: "FR",
        S: "SA",
        U: "SU",
    },
    dowInt2Abbr: {
        0: "SU",
        1: "MO",
        2: "TU",
        3: "WE",
        4: "TH",
        5: "FR",
        6: "SA",
    },
    dowSort: {
        MO: 1,
        TU: 2,
        WE: 3,
        TH: 4,
        FR: 5,
        SA: 6,
        SU: 0,

        M: 1,
        T: 2,
        W: 3,
        R: 4,
        F: 5,
        S: 6,
        U: 0,
    },
    // All possible days of week (from *_lynx.dbo.exam_dt_pattern)
    // MWF and TR are most common so make TR very visible ANG-4369
    allDows: [
        "MWF",
        "TR",
        "MW",
        "WF",
        "MF",
        "M",
        "W",
        "F",
        "T",
        "R",
        "S",
        "U",
        "MT",
        "TW",
        "WR",
        "RF",
        "FS",
        "SU",
        "MTW",
        "TWR",
        "WRF",
        "RFS",
        "FU",
        "MTWR",
        "TWRF",
        "WRFS",
        "MTWRF",
        "MTRF",
        "MTWF",
        "MWRF",
        "MR",
        "MTR",
        "MWR",
        "TF",
        "TRF",
        "TWF",
    ],
};
