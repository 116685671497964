import { SwarmSchedule } from "./SwarmSchedule";
import { EventSummary } from "./s25.event.summary.service";
import { BOARD_CONST } from "./s25.board.const";
import { Proto } from "../../pojo/Proto";
import { S25Const } from "../../util/s25-const";
import { S25Util } from "../../util/s25-util";
import { ElementRef } from "@angular/core";
import BoardEvent = SwarmSchedule.BoardEvent;
import DowChars = EventSummary.DowChars;
import DowChar = EventSummary.DowChar;
import ISOTimeString = Proto.ISOTimeString;
import BoardRoom = SwarmSchedule.BoardRoom;
import ISODateString = Proto.ISODateString;

function itemHash(item: BoardEvent) {
    if (item.uuid) return item.uuid;

    const { eventId, profileId, dow, startTime, endTime, roomId, room, boardUUID } = item;
    const key = `${eventId}-${profileId}-${dow}-${startTime}-${endTime}-${roomId || room?.roomId}-${boardUUID}`;
    return key.replace(/:/g, "").replace(/\$/g, "_");
}

function partialItemHash(item: BoardEvent) {
    const [first, second] = itemHash(item).split("-");
    return `${first}-${second}`;
}

function itemRowHash<T extends { roomId: any }>(item: T) {
    return String(item.roomId);
}

function colHash(dow: BoardEvent["dow"], startHour: number) {
    return dow.replace(/\s/g, "") + "-" + startHour;
}

function itemColumnHash<T extends { dow: BoardEvent["dow"]; startHour: number }>(item: T) {
    return colHash(item.dow, item.startHour);
}

function dowSort(a: DowChars | DowChar, b: DowChars | DowChar) {
    return BOARD_CONST.dowSort[a] - BOARD_CONST.dowSort[b];
}

function daysOfWeekMap(longDow: string) {
    if (longDow && !longDow.includes(" ")) return longDow; //longDow is really short version (short like MWF vs MO WE FR)

    return longDow
        .split(" ")
        .map((d: DowChars) => d && BOARD_CONST.dowMap[d])
        .sort(dowSort)
        .join("");
}

function daysOfWeekFilter(dow: DowChar) {
    return ["U", "M", "T", "W", "R", "F", "S"].includes(dow);
}

function minTimeStr(a: ISOTimeString, b: ISOTimeString) {
    return parseInt(a.replace(/:/g, "")) < parseInt(b.replace(/:/g, "")) ? a : b;
}

function maxTimeStr(a: ISOTimeString, b: ISOTimeString) {
    return minTimeStr(a, b) === a ? b : a;
}

function roomSort(a: BoardRoom, b: BoardRoom) {
    if (a.roomId === -1 && b.roomId !== -1) return -1;
    if (b.roomId === -1 && a.roomId !== -1) return 1;
    if (a.roomName.toLowerCase() < b.roomName.toLowerCase()) return -1;
    if (a.roomName.toLowerCase() > b.roomName.toLowerCase()) return 1;
    return 0;
}

function rowSort<T extends { objRef: BoardRoom }>(a: T, b: T) {
    return roomSort(a.objRef, b.objRef);
}

function roundDate(date: Date, nearestMinutes: number) {
    const nearestMs = S25Const.ms.min * nearestMinutes;
    return new Date(Math.round(date.getTime() / nearestMs) * nearestMs);
}

function roundNearestMinute(date: Date) {
    return roundDate(date, BOARD_CONST.nearestMinute);
}

function pixelsPerMinute() {
    return BOARD_CONST.colWidth / 60;
}

function getAdj(gridStartTime: ISODateString, gridEndTime: ISODateString) {
    return S25Util.date.diffMinutes(gridStartTime, gridEndTime) * pixelsPerMinute();
}

function getWidth(startTime: ISODateString, endTime: ISODateString) {
    return S25Util.date.diffMinutes(startTime, endTime) * pixelsPerMinute();
}

function removeBlocks(item: BoardEvent) {
    for (let block of item.blocks) block.remove();
}

function isVisible(start: Date, end: Date, displayStart: Date, displayEnd: Date) {
    return start < displayEnd && end > displayStart;
}

function isStraddle(start: Date, end: Date, displayStart: Date, displayEnd: Date) {
    return isVisible(start, end, displayStart, displayEnd) && (start < displayStart || end > displayEnd);
}

function updateStraddle(element: ElementRef["nativeElement"], item: any, displayStart: Date, displayEnd: Date) {
    const straddles = isStraddle(item.start, item.end, displayStart, displayEnd);
    if (!item.isStraddle && straddles) {
        item.isStraddle = true;
        element.removeClass("ngFlexGridDraggable");
        element.removeClass("ngAvailTypeId0");
        element.addClass("ngAvailTypeId2");
    } else if (item.isStraddle && !straddles) {
        item.isStraddle = false;
        element.addClass("ngFlexGridDraggable");
        element.removeClass("ngAvailTypeId2");
        element.addClass("ngAvailTypeId0");
    }
}

export const BoardUtil = {
    itemHash,
    partialItemHash,
    itemRowHash,
    colHash,
    itemColumnHash,
    dowSort,
    daysOfWeekMap,
    daysOfWeekFilter,
    minTimeStr,
    maxTimeStr,
    roomSort,
    rowSort,
    roundDate,
    roundNearestMinute,
    pixelsPerMinute,
    getAdj,
    getWidth,
    removeBlocks,
    isVisible,
    isStraddle,
    updateStraddle,
};
