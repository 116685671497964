import { Cache, Invalidate } from "../../decorators/cache.decorator";
import { Timeout } from "../../decorators/timeout.decorator";
import { S25Util } from "../../util/s25-util";
import { LoginService } from "../../services/login.service";
import { UserprefService } from "../../services/userpref.service";
import { DataAccess } from "../../dataaccess/data.access";
import { SwarmSchedule } from "./SwarmSchedule";
import { S25Const } from "../../util/s25-const";
import { LynxService } from "../../services/lynx.service";
import { jSith } from "../../util/jquery-replacement";
import { BoardOccUtil } from "./s25.board.occ.util";
import MessageRequestResponse = SwarmSchedule.MessageRequestResponse;

export class BoardService {
    @Timeout
    @Cache({ targetName: "BoardService", immutable: true })
    public static getBoards(): Promise<SwarmSchedule.BoardI[]> {
        return S25Util.all({
            boards: DataAccess.get("/board/boards.json"),
            sessionId: UserprefService.getSessionId(),
            loginData: LoginService.getLoginCached(),
        }).then((resp) => {
            const boards: SwarmSchedule.BoardRespI[] = resp?.boards?.boards?.board || [];
            return boards.map((board) => {
                return {
                    sharedUsers: board.sharedUsers || [],
                    shared: S25Util.toBool(board.shared),
                    owner: board.last_mod_user,
                    boardId: board.board_id,
                    boardName: board.board_name,
                    boardUUID: S25Const.instanceId + "$" + board.board_id,
                    username: resp.loginData.userName,
                    sessionId: resp.sessionId,
                    isOwner: resp.loginData.userName === board.last_mod_user,
                    locationQueryName: board.location_query_name,
                    eventQueryName: board.event_query_name,
                    lastPrepared: board.last_prepared,
                };
            });
        });
    }

    @Timeout
    public static async getBoard(
        boardId: number,
        boardUUID: string,
    ): Promise<{ board: SwarmSchedule.BoardRespI; allowXDrag?: boolean }> {
        const resp = await S25Util.all({
            board: DataAccess.get(`/board/board.json?board_id=${boardId}&boardUUID=${boardUUID}`),
            expSecEnable: LynxService.getExpSecEnabled(),
        });
        if (resp.board) resp.board.allowXDrag = resp.expSecEnable;
        return resp.board;
    }

    @Timeout
    @Invalidate({ serviceName: "BoardService", methodName: "getBoards" })
    public static shareBoard(boardId: number, boardUUID: string, username: string) {
        return DataAccess.put(`/board/share.json?board_id=${boardId}&boardUUID=${boardUUID}&username=${username}`);
    }

    @Timeout
    @Invalidate({ serviceName: "BoardService", methodName: "getBoards" })
    public static unshareBoard(boardId: number, boardUUID: string, username: string) {
        return DataAccess.delete(`/board/share.json?board_id=${boardId}&boardUUID=${boardUUID}&username=${username}`);
    }

    @Timeout
    public static lockBoard(boardUUID: string) {
        return DataAccess.put(`/board/lock.json?boardUUID=${boardUUID}`);
    }

    @Timeout
    public static unlockBoard(boardUUID: string) {
        return DataAccess.put(`/board/unlock.json?boardUUID=${boardUUID}`);
    }

    @Timeout
    public static lockItem(boardUUID: string, itemTo: SwarmSchedule.EventI) {
        let payload: any = itemTo;
        if (payload && !payload.ItemTO) {
            payload = { ItemTO: itemTo };
        }
        return DataAccess.put<{ message: string }>(`/board/lock/item.json?boardUUID=${boardUUID}`, payload);
    }

    @Timeout
    public static unlockItem(boardUUID: string, itemTo: SwarmSchedule.EventI) {
        let payload: any = itemTo;
        if (payload && !payload.ItemTO) {
            payload = { ItemTO: itemTo };
        }
        return DataAccess.put(`/board/unlock/item.json?boardUUID=${boardUUID}`, payload);
    }

    @Timeout
    public static dispatch(boardUUID: string, itemTos: SwarmSchedule.EventI[]) {
        let promises: any[] = [];
        jSith.forEach(itemTos, function (_, itemTo) {
            if (itemTo && !itemTo.ItemTO) {
                itemTo = { ItemTO: itemTo };
            }
            promises.push(DataAccess.put(`/board/dispatch.json?boardUUID=${boardUUID}`, itemTo));
        });
        return S25Util.all(promises);
    }

    @Timeout
    public static async subscribe(boardUUID: string) {
        const data = await DataAccess.get<MessageRequestResponse>(`/board/messages.json?boardUUID=${boardUUID}`);
        for (let message of data?.ItemRequestContainer?.messages || []) {
            if (message.item?.roomId) message.item.roomId = Number(message.item.roomId); // Sometimes roomId is a string...
        }
        return data;
    }

    public static _getOptimizerFile(runId: number, file: SwarmSchedule.OptimizerFile) {
        return DataAccess.get(`/s25_exec.GetPriorResults?run_id=${runId}&xml=${file}`);
    }

    @Timeout
    public static getOptimizerFile(
        runId: number,
        file: SwarmSchedule.OptimizerFile,
    ): Promise<SwarmSchedule.OptimizerData> {
        return BoardService._getOptimizerFile(runId, file).then((data) => {
            const arrays = {
                events: {
                    event: true,
                    occurrence: true,
                },
                spaces: {
                    space: true,
                    feature: true,
                    dates: true,
                },
                organizations: {
                    organization: true,
                    partition: true,
                },
                import_results: {
                    event: true,
                },
                partitions: {
                    partition: true,
                },
                features: {
                    feature: true,
                },
                journal: {
                    entry: true,
                },
                export_results: {
                    message: true,
                    issue: true,
                    item: true,
                },
            } as any;

            data = data && S25Util.prettyConv(data, null, arrays[file]);
            return data;
        });
    }

    @Timeout
    public static setOptimizerFile(runId: number, file: SwarmSchedule.OptimizerFile, xml: any) {
        return DataAccess.put(`/s25_exec.PutXml?run_id=${runId}&xml=${file}`, xml);
    }

    @Timeout
    public static refreshResults(runId: number) {
        return DataAccess.get(`/s25_exec.RefreshResults?run_id=${runId}`);
    }

    @Timeout
    public static saveBoard(model: SwarmSchedule.ModelI) {
        return BoardService.lockBoard(model.boardUUID).then(
            () => {
                console.log("lockDone");
                //new events file
                let events: any = [],
                    results = [].concat(model.origResults.results.result || []);

                jSith.forEach(model.items, (key, item) => {
                    let timesChanged = item.origStartTime !== item.startTime;
                    let patternChanged = item.origDow !== item.dow;
                    let origEvent = item.objRef.origEvent;
                    let result = model.origResultsMap[origEvent.key];

                    if (result) {
                        if (item.roomId === -1) {
                            result.outcome =
                                ["impossible", "notplaced"].indexOf(result.outcome) > -1 ? result.outcome : "notplaced";
                            delete result.space;
                        } else {
                            let roomChanged =
                                ((origEvent.room && parseInt(origEvent.room)) ||
                                    (result && S25Util.parseInt(result.space))) !== item.roomId;
                            result.space = item.roomId;
                            if (roomChanged) {
                                if (["notplaced", "preassigned", "impossible"].indexOf(result.outcome) > -1) {
                                    result.outcome = "placed";
                                }
                            }
                        }
                    } else if (item?.roomId > 0) {
                        result = {
                            name: item.itemName,
                            outcome: "placed",
                            space: item.roomId,
                            type: "EVENT",
                            key:
                                item.linkedItems.length === 0
                                    ? item.eventId + "-" + item.profileId
                                    : "BND-" +
                                      item.groupId +
                                      " " +
                                      item.linkedItems
                                          .filter(function (li: SwarmSchedule.LinkedItemI) {
                                              return li.eventId !== item.eventId || li.profileId !== item.profileId;
                                          })
                                          .map(function (li: SwarmSchedule.LinkedItemI) {
                                              return li.eventId + "-" + li.profileId;
                                          })
                                          .join(" "),
                        };
                        results.push(result);
                    }

                    if (result && item.roomId > 0 && (timesChanged || patternChanged || item.moved === "true")) {
                        result.moved = "true";
                    }

                    let event = S25Util.deepCopy(origEvent);
                    if (timesChanged || patternChanged || item.newpattern) {
                        if (patternChanged || item.newpattern) {
                            item.profileCode = BoardOccUtil.getNewProfileCode(item);
                            event.newpattern = item.profileCode;
                        }
                        item.occs = BoardOccUtil.getOccurrences(item);

                        delete event.occurrences;
                        delete event.dates;
                        event.occurrences = {
                            occurrence: item.occs.map(function (occ: SwarmSchedule.OccurrenceI) {
                                return {
                                    start: S25Util.date.toS25ISODateTimeStr(occ.reservation_start_dt),
                                    end: S25Util.date.toS25ISODateTimeStr(occ.reservation_end_dt),
                                };
                            }),
                        };
                    }

                    event._registered = S25Util.coalesce(event._registered || event.registered, "");
                    event._expected = S25Util.coalesce(event._expected || event.expected, "");
                    delete event.registered;
                    delete event.expected;
                    events.push(event);
                });

                let eventsXml = S25Util.s25Json2Xml({
                    events: {
                        "_xmlns:r25": "http://www.collegenet.com/r25",
                        "_xmlns:xl": "http://www.w3.org/1999/xlink",
                        "_xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
                        "_xsi:noNamespaceSchemaLocation": "../xsd/events.xsd",
                        _engine: "accl",
                        event: events,
                    },
                });

                let resultsXml = S25Util.s25Json2Xml({
                    results: {
                        "_xmlns:r25": "http://www.collegenet.com/r25",
                        "_xmlns:xl": "http://www.w3.org/1999/xlink",
                        _engine: "accl",
                        _average_util: model.origResults.results.average_util,
                        _overall_util: model.origResults.results.overall_util,
                        _placed: model.origResults.results.placed,
                        _notplaced: model.origResults.results.notplaced,
                        _impossible: model.origResults.results.impossible,
                        result: results,
                    },
                });

                return BoardService.setOptimizerFile(model.boardId, "events", eventsXml).then(function () {
                    //service deletes reuslts file
                    return BoardService.setOptimizerFile(model.boardId, "results", resultsXml).then(function () {
                        //so we add new results after
                        return BoardService.refreshResults(model.boardId).then(function () {
                            return BoardService.unlockBoard(model.boardUUID);
                        });
                    });
                });
            },
            (err: string) => {
                console.log("lock error");
                S25Util.errorText(err, "There was an error while saving. The board will now reload.");
            },
        );
    }
}
