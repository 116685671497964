import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Input } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { Table } from "../Table";

@TypeManagerDecorator("s25-ng-generic-table-text-preview")
@Component({
    selector: "s25-ng-generic-table-fade-preview",
    template: `
        <div
            class="preview"
            [class.clickable]="!short"
            (click)="toggleShowAll()"
            (keydown.enter)="toggleShowAll()"
            [tabindex]="short ? -1 : 0"
            [attr.aria-label]="ariaLabel"
            [title]="ariaLabel"
            [class.show]="short || showAll"
        >
            <div class="preview-snippet">
                <s25-ng-table-cell [defaults]="cell"></s25-ng-table-cell>
            </div>
        </div>
    `,
    styles: `
        :host {
            --max-height-px: calc(1px * var(--max-height, 90px));
            --fade-height-px: calc(1px * var(--fade-height, 40px));
            --fade-start: calc(100% - var(--fade-height-px));
        }

        .preview.clickable {
            cursor: pointer;
        }

        .preview:not(.show) .preview-snippet {
            max-height: var(--max-height-px);
            -webkit-mask-image: linear-gradient(to bottom, black var(--fade-start), transparent var(--max-height-px));
            mask-image: linear-gradient(to bottom, black var(--fade-start), transparent var(--max-height-px));
            overflow: hidden;
        }
    `,
})
export class GenericTableFadePreviewComponent implements AfterViewInit {
    @Input({ required: true }) cell: Table.Cell;
    @HostBinding("style.--max-height") @Input() maxHeight = 90; // Pixels
    @HostBinding("style.--fade-height") @Input() fadeHeight = 40; // Pixels

    showAll = false;
    short = false;
    ariaLabel = "";

    constructor(
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    ngAfterViewInit() {
        // If rendered content is not max height we don't need to be able to toggle showing everything
        if (this.elementRef.nativeElement.offsetHeight < this.maxHeight) {
            this.short = true;
        }
        this.updateAriaLabel();
    }

    toggleShowAll() {
        this.showAll = !this.showAll;
        this.updateAriaLabel();
    }

    updateAriaLabel() {
        this.ariaLabel = this.short ? "" : `Click to show ${this.showAll ? "less" : "more"}`;
        this.changeDetector.detectChanges();
    }
}
